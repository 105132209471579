<template>
  <div class="item-search-container mb-1 counter-position">
    <span class="item-search-label">{{ label }}</span>
    <div class="navbar-count">{{ objects }}/{{ count }}</div>
  </div>
</template>

<script>
export default {
  name: 'counter',
  props: {
    label: {
      type: String,
      default: 'Знайдено'
    },
    objects: {
      type: Number,
      default: 0
    },
    count: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style lang="sass">
.counter-position
  position: absolute
  bottom: 8px
</style>
